import axios from 'axios';

const requestGet = async (endPoint) => {
    try {
        const response = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + endPoint,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'en_US',
            }
        })

        return response;
    } catch (error) {
        console.error('Error fetching GET request:', error);
        throw error;
    }
};

const requestPost = async (endPoint, body, encrypt) => {
    try {
        const response = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + endPoint,
            data: encrypt ? { data: await encryptData(body) } : body,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'en_US',
            }
        })

        return response;
    } catch (error) {
        console.error('Error fetching POST request:', error);
        throw error;
    }
};

//encrypt
export const encryptData = async(data) => {
    return await axios({
        method: 'post',
        url: process.env.REACT_APP_ENV == 'local' ? `${process.env.REACT_APP_API_URL}/tests/encrypt` : `${process.env.REACT_APP_CRYPTO_API_URL}/encrypt` ,
        data: data
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.error('Error fetching Get:', error);
    });
}

//Plans
export const getPlansSale = async(offset = 0, limit = 10) => {
    return requestGet(`/plans/sale?offset=${offset}&limit=${limit}`);
}
export const getPlanSaleByType = async(data) => {
    const { type, offset, limit } = data;
    return requestGet(`/plans/sale/${type}?offset=${offset}&limit=${limit}`);
}

//Order
export const createOrder = async(data) => {
    data['refPlanId'] = data?.id;
    data['language'] = "en_US";

    return requestPost('/orders', data, true);
}
export const getOrderDetail = async(data) => {
    return requestGet(`/orders/sale-detail?query=${data}`);
}

// Esim
export const getEsimQr = async(data) => {
    return requestPost('/esim', data, true);
}

//ReCaptcha
export const reCaptchaRespone = async(data) => {
    return requestPost(`/recaptcha/check-token`, data, false);
}