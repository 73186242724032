import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Col, Dropdown, Row, Drawer, Input, QRCode, Spin, notification } from "antd";
import { RightOutlined, QrcodeOutlined, GlobalOutlined, MenuFoldOutlined, DownOutlined, UpOutlined, PlayCircleOutlined } from "@ant-design/icons";

import { HeaderWrapper } from "../ComponentStyle";
import { getEsimQr } from "../../utils/api";

import { useTranslation } from "react-i18next";

function doDownload(url, fileName) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const Header = () => {

    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState('en');
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isQrGenerated, setIsQrGenerated] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isLanguageChanged, setIsLanguageChanged] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);

    const [transactionNumber, setTransactionNumber] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [esimDetails, setEsimDetails] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    
    const [api, contextHolder] = notification.useNotification();

    const isSubscribedRef = useRef(false);
    const isQrValidRef = useRef(false);
    const hasCountdownStartedRef = useRef(false);

    const navigate = useNavigate();

    const itemLanguage = [
        {
            key: 'kh',
            label: "ខ្មែរ",
        },{
            key: 'en',
            label: "English",
        }
    ];
    const formGetQr = [
        {
            name: 'tranx_number',
            label: 'Transaction Number',
            type: 'input',
            placeholder: 'Enter transaction number',
            span: 24,
            required: true
        },{
            name: 'payment_id',
            label: 'Payment ID',
            type: 'input',
            placeholder: 'Enter payment ID',
            span: 24,
            required: true
        },
    ];

    const onClick = ({key}) => {
        setLanguage(key);
        i18n.changeLanguage(key);
        document.documentElement.lang = key;
    
        if (isMobileView) {
            setIsLanguageChanged(false);
        }
    };
    const toggleLanguageChange = () => {
        if (isQrGenerated) {
            setIsQrGenerated(false);
        }
    
        setIsLanguageChanged(prevState => !prevState);
    };
    const handleInputChange = (name, value) => {
        const handlers = {
            'tranx_number': setTransactionNumber,
            'payment_id': setPaymentId,
        };
    
        if (handlers[name]) {
            handlers[name](value);
        }
    };
    const openDrawer = () => setIsOpen(true);
    const closeDrawer = () => setIsOpen(false);
    const navigateHome = () => {
        setIsQrGenerated(false);
        setIsLanguageChanged(false);
        navigate('/');
    };
    const handleSaveQRCode = () => {
        const qrCodeElement = document.getElementById('myqrcode')?.querySelector('svg');
    
        if (!qrCodeElement) {
            api.error({
                message: 'QR Code not found.',
                placement: 'topRight',
            });
            return;
        }
    
        const svgData = new XMLSerializer().serializeToString(qrCodeElement);
        const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const downloadUrl = URL.createObjectURL(blob);
    
        doDownload(downloadUrl, 'QRCode.svg');
    
        api.success({
            message: 'QR Code downloaded successfully!',
            placement: 'topRight',
        });
    };
    const clearInfo = () => {
        setTransactionNumber('');
        setPaymentId('');
        setEsimDetails({});
        setPaymentStatus(false);
        isQrValidRef.current = false;
    };
    const handleEsimInfo = () => {
        setIsQrGenerated(!isQrGenerated);
        setPaymentStatus(false);
        isQrValidRef.current = false;
        clearInfo(); 
    };
   
    const submitGetQr = async() => {
        setIsLoading(true)
        if( !transactionNumber || !paymentId ){
            setIsFormSubmitted(true);
            setIsLoading(false)
            return;
        }

        await handleGetEsimInfo();
        setIsLoading(false)
    };
    const handleGetEsimInfo = async () => {
        const data = {
            "order_id": transactionNumber,
            "unique_code": paymentId,
        };
    
        try {
            const response = await getEsimQr(data);
            if (response.status === 201) {
                const result = response.data?.data;
                processEsimResponse(result);
            }
        } catch (error) {
            handleEsimError(error);
        }
    };
    const processEsimResponse = (result) => {
        setEsimDetails(result);
    
        if (result.required_payment) {
            showNotification(
                "info",
                "Payment Required",
                "Please pay the remaining amount before requesting a QR code."
            );
            return;
        } else {
            setPaymentStatus(true);
        }
        
        if (result.qrCodeContent !== 'null') {
            isQrValidRef.current = true;
        } else {
            if(!hasCountdownStartedRef.current){
                showNotification(
                    "info",
                    "Processing QRCode",
                    "Please wait for the QR code to be generated."
                );
                startCountDown();
                hasCountdownStartedRef.current = true;
            }
        }
    };
    const handleEsimError = (error) => {
        if (error.response?.status === 404 && error.response?.data?.message === "Not Found!") {
            isQrValidRef.current = false;
            showNotification(
                "error",
                "Not Found!",
                "Please check your information and try again."
            );
        } else {
            console.error("Unexpected error:", error);
        }
    };
    const startCountDown = async (repeatCount = 6) => {
        let secondsToGo = 10;

        const timer = setInterval(async () => {
            secondsToGo -= 1;
    
            if (secondsToGo <= 0) {
                clearInterval(timer);
    
                if (repeatCount > 1) {
                    await handleGetEsimInfo();

                    if (!isQrValidRef.current) {
                        startCountDown(repeatCount - 1);
                    }
                } else if (!isQrValidRef.current) {
                    clearInfo();
                }
            }
        }, 1000);

    };
    const showNotification = (type, message, description) => {
        api[type]({
            message,
            description,
            placement: "topRight",
            duration: type === "info" ? 5 : 10,
            showProgress: true,
            pauseOnHover: true,
        });
    };

    useEffect(() => {
        const updateMobileView = () => {
            if (window.innerWidth < 768) {
                setIsMobileView(true);
            }
        };
    
        updateMobileView();
    }, []);
    
    useEffect(() => {
        if (!isSubscribedRef.current) {
            const storedLanguage = localStorage.getItem('i18nextLng');
            const defaultLanguage = 'kh';
            const languageKey = storedLanguage || defaultLanguage;
    
            onClick({ key: languageKey });
            isSubscribedRef.current = true;
        }
    }, []);

    return (
        <HeaderWrapper>
            {contextHolder}
            <Spin spinning={isLoading} fullscreen />
            <Row className="header-container" id="header-style-custom">
                <Col span={12} className="logo item-start" onClick={() => navigateHome()}>
                    <img src="/images/esim-logo-rectangle-fit-tr.png" alt="logo-esim" />
                </Col>
                {
                    isMobileView ? (
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" onClick={openDrawer} className="button-menu-mobile">
                                <MenuFoldOutlined />
                            </Button>
                        </Col>
                    ) : (
                        <Col span={12} className="menu-screen-pc item-end">
                            <Button className="button-menu" onClick={handleEsimInfo}>
                                <QrcodeOutlined /> Get Your eSIM
                            </Button>
                            <Button className="button-menu" onClick={() => window.open('https://www.youtube.com/watch?v=yikN-UgysnU&pp=ygUQYWJhIHBheW1lbnQgYmlsbA%3D%3D', '_blank')}>
                                <PlayCircleOutlined /> {t('How to do payment on ABA Bank')}
                            </Button>
                            <Drawer
                                title="Get Your eSIM"
                                placement="right"
                                className="drawer-esim"
                                onClose={handleEsimInfo}
                                open={isQrGenerated}
                                getContainer={() => document.getElementById('header-style-custom')}
                                height="100%"
                                width={450}
                            >
                                {isQrGenerated && (
                                    <div className="wrapper-get-plan">
                                        {paymentStatus ? (
                                            isQrValidRef.current ? (
                                                <Row className="content-qr">
                                                    <Col span={24} className="Qr-box" id="myqrcode">
                                                        <h1>eSIM QR Code</h1>
                                                        <QRCode
                                                            type="svg"
                                                            errorLevel="H"
                                                            value={esimDetails?.qrCodeContent}
                                                            icon="/favicon.ico"
                                                            size={300}
                                                        />
                                                    </Col>
                                                    <Col span={24} className="action-box">
                                                        <Row gutter={[16, 16]}>
                                                            <Col span={12}>
                                                                <Button className="default-button button-save" type="primary" onClick={handleSaveQRCode}>
                                                                    Save QR
                                                                </Button>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Button className="default-button button-request-other" type="primary" onClick={clearInfo}>
                                                                    Clear
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row className="processing-general">
                                                    <Col span={24} className="content-processing">
                                                        <div className="loader"></div>
                                                        <h1>Processing QR Code</h1>
                                                        <p>
                                                            Processing your QR code. This may take a moment—please stay connected to Wi-Fi and keep this screen open. Thank you for your patience!
                                                        </p>
                                                    </Col>
                                                </Row>
                                            )
                                        ) : (
                                            <Row className="content-get-plan">
                                                {formGetQr.map((item, index) => (
                                                    <Col span={item.span} key={index} className="wrapper-box-input">
                                                        <label>
                                                            {item.label} {item.required && <span className="required">*</span>}
                                                        </label>
                                                        <Input
                                                            type={item.type}
                                                            name={item.name}
                                                            placeholder={item.placeholder}
                                                            size="large"
                                                            className={
                                                                isFormSubmitted
                                                                    ? item.name === 'tranx_number'
                                                                        ? !transactionNumber
                                                                            ? 'class-required input-box'
                                                                            : 'input-box'
                                                                        : !paymentId
                                                                        ? 'class-required input-box'
                                                                        : 'input-box'
                                                                    : 'input-box'
                                                            }
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                            onClear={() => handleInputChange(item.name, '')}
                                                            allowClear
                                                        />
                                                    </Col>
                                                ))}
                                                <Col span={24}>
                                                    <Button className="button-submit" type="primary" onClick={submitGetQr}>
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                )}
                            </Drawer>
                            <Dropdown
                                menu={{
                                    items: itemLanguage,
                                    onClick,
                                }}
                            >
                                <Button onClick={(e) => e.preventDefault()} className="button-menu">
                                    <GlobalOutlined /> {t(`global.${language}`)}
                                </Button>
                            </Dropdown>
                        </Col>
                    )
                }
                <Drawer 
                    title="Setting" 
                    placement="bottom"
                    height="100%"
                    className="drawer-menu"
                    closable={false}
                    onClose={closeDrawer} 
                    open={isOpen}
                    getContainer={() => document.getElementById('header-style-custom')}
                >
                    <Row className="content">
                        <Col span={24} className="menu-item cursor">
                            <div className="menu-item-has-child cursor" onClick={toggleLanguageChange}>
                                <p><GlobalOutlined className="icon" /> {t('global.language')}</p>
                                <h2>{t(`global.${language}`)}</h2>
                            </div>
                            <Drawer
                                title="Select Language" 
                                placement="bottom"
                                className="drawer-language"
                                onClose={toggleLanguageChange} 
                                open={isLanguageChanged}
                                getContainer={() => document.getElementById('header-style-custom')}
                            >
                                <Row>
                                    {itemLanguage.map((item, index) => (
                                        <Col
                                            span={24}
                                            className="list-language"
                                            key={index}
                                            onClick={() => onClick({ key: item.key })}
                                        >
                                            <p>{item.label}</p>
                                            <div className="check-box">
                                                <div className={item.key === language ? 'black-dot' : 'white-dot'} />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Drawer>
                        </Col>
                        <Col span={24} className="menu-item cursor">
                            <div className="menu-item-has-child cursor" onClick={handleEsimInfo}>
                                <p><QrcodeOutlined className="icon" /> Get Your eSIM</p>
                                {isQrGenerated ? <UpOutlined /> : <DownOutlined />}
                            </div>
                            <Drawer
                                title="Get Your eSIM" 
                                placement="bottom"
                                className="drawer-esim"
                                onClose={handleEsimInfo} 
                                open={isQrGenerated}
                                getContainer={() => document.getElementById('header-style-custom')}
                                height={500}
                            >
                                {isQrGenerated && (
                                    <div className="wrapper-get-plan">
                                        {paymentStatus ? (
                                            <div>
                                                {isQrValidRef.current ? (
                                                    <Row className="content-qr">
                                                        <Col span={24} className="Qr-box" id="myqrcode">
                                                            <h1>eSIM QR Code</h1>
                                                            <QRCode
                                                                type="svg"
                                                                errorLevel="H"
                                                                value={esimDetails?.qrCodeContent}
                                                                icon="/favicon.ico"
                                                                size={300}
                                                            />
                                                        </Col>
                                                        <Col span={24} className="action-box">
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={12}>
                                                                    <Button
                                                                        className="default-button button-save"
                                                                        type="primary"
                                                                        onClick={handleSaveQRCode}
                                                                    >
                                                                        Save QR
                                                                    </Button>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Button
                                                                        className="default-button button-request-other"
                                                                        type="primary"
                                                                        onClick={clearInfo}
                                                                    >
                                                                        Clear
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row className="processing-general">
                                                        <Col span={24} className="content-processing">
                                                            <div className="loader"></div>
                                                            <h1>Processing QR Code</h1>
                                                            <p>
                                                                Processing your QR code. This may take a moment—please stay connected to Wi-Fi and keep this screen open. Thank you for your patience!
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        ) : (
                                            <Row className="content-get-plan">
                                                {formGetQr.map((item, index) => (
                                                    <Col span={item.span} key={index} className="wrapper-box-input">
                                                        <label>
                                                            {item.label} {item.required && <span className="required">*</span>}
                                                        </label>
                                                        <Input
                                                            type={item.type}
                                                            name={item.name}
                                                            placeholder={item.placeholder}
                                                            size="large"
                                                            className={
                                                                isFormSubmitted
                                                                    ? item.name === 'tranx_number'
                                                                        ? !transactionNumber
                                                                            ? 'class-required input-box'
                                                                            : 'input-box'
                                                                        : !paymentId
                                                                        ? 'class-required input-box'
                                                                        : 'input-box'
                                                                    : 'input-box'
                                                            }
                                                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                                            onClear={() => handleInputChange(item.name, '')}
                                                            allowClear
                                                        />
                                                    </Col>
                                                ))}
                                                <Col span={24}>
                                                    <Button className="button-submit" type="primary" onClick={submitGetQr}>
                                                        Submit
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                )}
                            </Drawer>
                        </Col>
                        <Col span={24} className="menu-item cursor">
                            <div
                                className="menu-item-has-child cursor"
                                onClick={() =>
                                    window.open(
                                        'https://www.youtube.com/watch?v=yikN-UgysnU&pp=ygUQYWJhIHBheW1lbnQgYmlsbA%3D%3D',
                                        '_blank'
                                    )
                                }
                            >
                                <p><PlayCircleOutlined className="icon" /> {t('How to do payment on ABA Bank')}</p>
                                <RightOutlined />
                            </div>
                        </Col>
                    </Row>
                    <Button className="button-close-drawer" onClick={closeDrawer}>
                        Close
                    </Button>
                </Drawer>
            </Row>
        </HeaderWrapper>
    );
}

export default Header;
