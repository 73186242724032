
async function encryptHMAC(publicKey, string) {
    
    const encoder = new TextEncoder();
    const data = encoder.encode(string);
    const key = encoder.encode(publicKey);
    const hmac = crypto.subtle?.importKey(
        "raw",
        key,
        { name: "HMAC", hash: { name: "SHA-512" } },
        false,
        ["sign"]
    ).then(key => crypto.subtle.sign("HMAC", key, data));
    
    return hmac.then(signature => {
        const byteArray = new Uint8Array(signature);
        const base64String = btoa(String.fromCharCode.apply(null, byteArray));
        return base64String;
    });
}

export { encryptHMAC };

