import {React, useEffect, useState, useRef} from 'react';
import { useNavigate } from "react-router-dom";

import { Row, Col, Button } from 'antd';
import { CalendarOutlined, WifiOutlined, GlobalOutlined } from '@ant-design/icons'

import { WrapperPlanCard } from './ComponentStyle';

const PlanCard = (propPlans) => {

    const navigate = useNavigate();
    
    const [planData, setPlanData] = useState([]);
    
    const onBuyNow = (selectedPlan) => {
        navigate('/order-detail', {state: {selectedPlan}})
    };
    
    useEffect(() => {
        const plans = propPlans.propPlans;
        plans?.sort((a, b) => a.price - b.price);

        if(plans){
            setPlanData(plans);
        }

    }, [propPlans.propPlans]);

    return (
        <WrapperPlanCard >
            <Row id='plans' className='wrapper-plan-card' justify={'center'} gutter={[16, 16]}>
                {
                    planData.map((plan, index) => 
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} key={index} className='plan-item'>
                            <Row className='plan-card'>
                                <Col span={24} className='box-style'>
                                    <h1 style={{textAlign: 'start'}}>{plan.name}</h1>
                                </Col>
                                <Col span={24} className='box-style'>
                                    <p><GlobalOutlined className='icon-style'/> coverage </p>
                                    <h1>Japan</h1>
                                </Col>
                                <Col span={24} className='box-style'>
                                    <p><WifiOutlined className='icon-style'/> data </p>
                                    <h1>{plan.data}</h1>
                                </Col>
                                <Col span={24} className='box-style'>
                                    <p><CalendarOutlined className='icon-style'/> validity </p>
                                    <h1>{plan.days} Days</h1>
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" className='button-buy' onClick={() => onBuyNow(plan)}>
                                        <span>Buy Now</span>
                                        <h1 >USD {plan.price}</h1>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    )
                }
            </Row>
        </WrapperPlanCard>
    );
}

export default PlanCard;
