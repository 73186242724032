import styled from "@emotion/styled";

const DefaultContainer = styled.div`
    padding: 0px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;

    .container-index{
        max-width: 1400px;
        min-height: calc(100vh - 312px);
        .button-dropdown-plans{
            background-color: #ffff;
            color: #17375E;
            border-radius: 5px;
            height: 40px;
            width: 40px;
            font-size: 20px;
            border: none;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
        .button-dropdown-plans:active{
            color: #ffff !important;
            background-color: #17375E !important;
        }
        .button-dropdown-plans:hover{
            color: #ffff !important;
            background-color: #17375E !important;
        }
        @media (max-width: 430px) {
            h1{
                font-size: 16px !important;
                margin-bottom: 18px;
                margin-top: 0px;
            }
            p{
                font-size: 14px !important;
                margin: 0px;
            }
        }
    }
`;

const HeaderWrapper = styled.div`
    with: 100%;
    height: fit-content;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 18px;
    position: sticky;
    top: 0px;
    z-index: 100;

    .item-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-start{
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .item-end{
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .header-container{
        width: 1400px;
        padding: 18px 0px;
        .logo{
            img{
                height: 50px;
                cursor: pointer;
            }
        }
        .menu-screen-pc{
            .ant-btn{
                border: none !important;
                box-shadow: none !important;

            }
            .button-menu{
                margin-left: 6px;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            
        }
        .button-menu{
            background-color: #ffff;
            color: #17375E;
            height: 40px;
            font-size: 16px;
        }
        .button-menu-mobile{
            background-color: #ffff;
            color: #17375E;
            border-radius: 5px;
            height: 40px;
            width: 40px;
            font-size: 20px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
        .button-menu-mobile:active{
            color: #ffff !important;
            background-color: #17375E !important;
        }
        .button-menu-mobile:hover{
            color: #ffff !important;
            background-color: #17375E !important;
        }

        .modal-get-qr{
            .ant-modal{
                width: 350px !important;
            }
            .ant-modal-content{
                text-align: start;
                .ant-modal-body{
                    .content-qr{
                        margin-top: 16px;
                        .Qr-box{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            h1{
                                font-size: 22px;
                                margin: 0px 0px 16px 0px;
                                color: #1c1c1c;
                            }
                        }
                        .action-box{
                            .default-button{
                                width: 100%;
                                background-color: #17375E;
                                color: #ffff;
                                height: 40px;
                                border-radius: 8px;
                            }
                            .button-save{
                                background-color: #17375E;
                            }
                            .button-request-other{
                                background-color: #ffff;
                                color: #17375E;
                                border: 1px solid #17375E;
                            }
                        }
                    }
                    .content-get-plan{
                        .wrapper-box-input{
                            margin-bottom: 16px;
                            label{
                                font-size: 14px;
                                font-weight: 400;
                            }
                            .required{
                                color: #ff0000;
                            }
                            .class-required{
                                border: 1px solid #ff0000 !important;
                            }
                            .input-box{
                                width: 100%;
                                height: 40px;
                                border-radius: 8px;
                                margin-top: 8px;
                            }
                        }
                        .button-submit{
                            width: 100%;
                            background-color: #17375E;
                            color: #ffff;
                            height: 40px;
                            border-radius: 8px;
                        }
                    }
                }
                .ant-modal-footer{
                    display: none;
                }
            }
        }
        
        .drawer-menu {
            text-align: start;
            .ant-drawer-header{
                padding: 16px;
                .ant-drawer-title{
                    font-size: 24px;
                }
            }
            .ant-drawer-body{
                position: relative;
                padding: 16px;
                .button-close-drawer{
                    position: absolute;
                    bottom: 16px;
                    right: 16px;
                    width: calc(100% - 32px);
                    height: 45px;
                    background: #F65B56;
                    color: #ffff;
                    border-radius: 10px;
                    text-transform: capitalize;
                }
            }

            .content{
                .menu-item{
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid #17375E;
                    p{
                        font-size: 16px;
                        margin: 0px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                    .icon{
                        margin-right: 5px;
                    }
                    .menu-item-has-child{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        h2{
                            font-size: 16px;
                            margin: 0px;
                            color: #17375E;
                        }
                    }
                    .wrapper-change-language{
                        width: 100%;
                            
                        .radio-group{
                            width: calc(100% - 25px);
                            margin-left: 25px;
                            margin-top: 16px;
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            flex-direction: column;
                            
                            .radio-item{
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }
                    .wrapper-get-plan{
                        width: 100%;
                        .content-qr{
                            margin-top: 16px;
                            .Qr-box{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                h1{
                                    font-size: 18px;
                                    margin: 0px 0px 16px 0px;
                                    color: #1c1c1c;
                                }
                            }
                            .action-box{
                                .default-button{
                                    width: 100%;
                                    background-color: #17375E;
                                    color: #ffff;
                                    height: 40px;
                                    border-radius: 8px;
                                }
                                .button-save{
                                    background-color: #17375E;
                                }
                                .button-request-other{
                                    background-color: #ffff;
                                    color: #17375E;
                                    border: 1px solid #17375E;
                                }
                            }
                        }
                        .content-get-plan{
                            margin-left: 25px;
                            margin-top: 16px;
    
                            .wrapper-box-input{
                                margin-bottom: 16px;
                                label{
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                                .required{
                                    color: #ff0000;
                                }
                                .class-required{
                                    border: 1px solid #ff0000 !important;
                                }
                                .input-box{
                                    width: 100%;
                                    height: 40px;
                                    border-radius: 8px;
                                    margin-top: 8px;
                                }
                            }
                            .button-submit{
                                width: 50%;
                                background-color: #17375E;
                                color: #ffff;
                                height: 40px;
                                border-radius: 8px;
                            }
                        }
                    }
                }
                .cusor{
                    cursor: pointer;
                }
            }
        }

        .drawer-language{
            text-align: start;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            .ant-drawer-header{
                padding: 16px;
                .ant-drawer-title{
                    font-size: 18px;
                }
                .ant-drawer-header-title{
                    display: flex;
                    flex-direction: row-reverse;
                    .ant-drawer-close{
                        margin-right: 0px;
                    }
                }
            }
            .ant-drawer-body{
                position: relative;
                padding: 16px;
                .button-close-drawer{
                    position: absolute;
                    bottom: 16px;
                    right: 16px;
                    width: calc(100% - 32px);
                    height: 45px;
                    background: #F65B56;
                    color: #ffff;
                    border-radius: 10px;
                    text-transform: capitalize;
                }
            }

            .list-language{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #17375E;
                p{
                    margin: 16px 0px;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
                .check-box{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 2px solid #17375E;
                    background-color: #ffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .white-dot{
                        background-color: #ffff;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
                    .black-dot{
                        background-color: #17375E;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
                }
            }
        }
        .drawer-esim{
            text-align: start;
            .ant-drawer-header{
                padding: 16px;
                .ant-drawer-title{
                    font-size: 18px;
                }
                .ant-drawer-header-title{
                    display: flex;
                    flex-direction: row-reverse;
                    .ant-drawer-close{
                        margin-right: 0px;
                    }
                }
            }
            .ant-drawer-body{
                padding: 16px;
                .wrapper-get-plan{
                    width: 100%;
                    .content-qr{
                        margin-top: 16px;
                        .Qr-box{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            h1{
                                font-size: 18px;
                                color: #1c1c1c;
                                margin-top: 0px;
                            }
                        }
                        .action-box{
                            .default-button{
                                width: 100%;
                                background-color: #17375E;
                                color: #ffff;
                                height: 40px;
                                border-radius: 8px;
                            }
                            .button-save{
                                background-color: #17375E;
                            }
                            .button-request-other{
                                background-color: #ffff;
                                color: #17375E;
                                border: 1px solid #17375E;
                            }
                        }
                    }
                    .content-get-plan{
                        margin-top: 16px;

                        .wrapper-box-input{
                            margin-bottom: 16px;
                            label{
                                font-size: 14px;
                                font-weight: 400;
                            }
                            .required{
                                color: #ff0000;
                            }
                            .class-required{
                                border: 1px solid #ff0000 !important;
                            }
                            .input-box{
                                width: 100%;
                                height: 40px;
                                border-radius: 8px;
                                margin-top: 8px;
                            }
                        }
                        .button-submit{
                            width: 100%;
                            background-color: #17375E;
                            color: #ffff;
                            height: 40px;
                            border-radius: 8px;
                        }
                    }
                    .processing-general{
                        .content-processing{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            h1{
                                font-size: 21px;
                                margin-bottom: 16px;
                                color: #17375E;
                            }
                            p{
                                font-size: 14px;
                                margin: 0px;
                                color: #2d2d2d;
                            }

                            /* HTML: <div class="loader"></div> */
                            .loader {
                                width: 100px;
                                aspect-ratio: 1;
                                display: grid;
                                border: 8px solid #0000;
                                border-radius: 50%;
                                border-color: #ccc #0000;
                                animation: l16 1s infinite linear;
                            }
                            .loader::before, .loader::after {    
                                content: "";
                                grid-area: 1/1;
                                margin: 2px;
                                border: inherit;
                                border-radius: 50%;
                            }
                            .loader::before {
                                border-color: #17375E #0000;
                                animation: inherit; 
                                animation-duration: 0.5s;
                                animation-direction: reverse;
                            }
                            .loader::after {
                                margin: 8px;
                            }
                            @keyframes l16 { 
                                100%{transform: rotate(1turn)}
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px){
            .drawer-esim{
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
            .logo{
                img{
                    height: 40px;
                    cursor: pointer;
                }
            }
        }
        @media screen and (max-width: 430px){
            margin-bottom: 0px;
        }
    }
`;

const FooterWrapper = styled.div`
    with: 100%;
    height: fit-content;
    background-color: #17375E;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    padding: 0px 18px;

    .footer-container{
        width: 1400px;
        padding: 36px 0px;
        color: #ffff;
        h1{
            margin-top: 0px;
        }
        .about-us-info{
            text-align: start;
            h1{
                margin-top: 0px;
            }
        }
        .contact-info{
            .add-margin{
                margin-bottom: 10px;
            }
            .contact-item{
                display: flex;
                justify-content: start;
                align-items: center;
                p{
                    margin: 0px;
                    padding-left: 18px;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
        }
        .copyright{
            text-align: end;
            img{
                height: 50px;
            }
        }
    }
    .footer-container-mobile{
        padding: 18px;
        width: 100%;
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: #17375E;

    }

    @media screen and (max-width: 768px){
        .footer-container{
            .copyright{
                margin-top: 16px;
                img{
                    height: 40px;
                }
            }
        } 
    }
`;

const WrapperPlanCard = styled.div`
    .wrapper-plan-card{
        .plan-item{
            .plan-card{
                width: 100%;
                height: 100%;
                padding: 18px;
                background-color: #17375E;
                border: 1px solid #eeee;
                border-radius: 10px;

                .icon-style{
                    margin-right: 5px;
                }
                .price-color{
                    color: #F65B56;
                    font-size: 18px;
                    font-weight: 700;
                }
                h1{
                    font-size: 16px;
                    color: #ffff;
                    font-weight: 600;
                    text-transform: capitalize;
                    margin: 0px;
                }
                p{
                    margin: 0px;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
                .plan-title{
                    text-align: start;
                    widht: 100%;
                    position: relative;
                    .flat-image{
                        position: absolute;
                        right: 0px;
                        top: -40px;
                        border: 1px solid #eeee;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                        img{
                            border-radius: 3px;
                        }
                    }
                }
                .box-style{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #ffffff;
                    border-bottom: 1px solid #ffff;
                    padding: 16px 0px;

                }
                .button-buy{
                    width: 100%;
                    margin-top: 16px;
                    height: fit-content;
                    background-color: #F65B56;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    line-height: unset;
                    padding: 8px;
                    span{
                        font-size: 14px;
                        font-weight: 500;
                    }
                    h1{
                        margin: 0px;
                        font-size: 20px;
                    }
                }

                @media (max-width: 430px) {

                }
            }
        }
    }
`;

const WrapPageNotFound = styled.div`
    width: 100vw;
    height: 100vh;
    background: #17375E;
     
     .wrapper {
         display: grid;
         grid-template-columns: 1fr;
         justify-content: center;
         align-items: center;
         height: 100%;
         overflow-x: hidden;
     
         .container {
            margin: 0 auto;
            transition: all 0.4s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
    
            // Scene for the Parallax Effect
            .scene {
                position: absolute;
                width: 100vw;
                height: 100vh;
                vertical-align: middle;
                overflow: hidden;
            }
     
            // All elements' containers
            .one,
            .two,
            .three,
            .circle,
            .p404 {
                width: 60%;
                height: 60%;
                top: 0% !important;
                left: 20% !important;
                min-width: 400px;
                min-height: 400px;
    
                .content {
                    width: 600px;
                    height: 600px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    animation: content 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
    
                    @keyframes content {
                        0% {
                            width: 0;
                        }
                    }
    
                    // Pieces
                    .piece {
                        width: 200px;
                        height: 80px;
                        display: flex;
                        position: absolute;
                        border-radius: 80px;
                        z-index: 1;
                        animation: pieceLeft 8s cubic-bezier(1, 0.06, 0.25, 1) infinite both;
    
    
                        @keyframes pieceLeft {
                            0% {}
                            50% {
                                left: 80%;
                                width: 10%;
                            }
                            100% {}
                        }
    
                        @keyframes pieceRight {
                            0% {}
                            50% {
                                right: 80%;
                                width: 10%;
                            }
                            100% {}
                        }
    
                    }
                }
    
                @media screen and (max-width: 799px) {
                    width: 90%;
                    height: 90%;
                    top: 0% !important;
                    left: 5% !important;
                    min-width: 280px;
                    min-height: 280px;
                }
    
                @media screen and (max-height: 660px) {
                    min-width: 280px;
                    min-height: 280px;
                    width: 60%;
                    height: 60%;
                    top: 0% !important;
                    left: 20% !important;
                }
            }
    
            // Text and Button container
            .text {
                width: 100%;
                height: 40%;
                min-width: 400px;
                min-height: 500px;
                position: absolute;
                margin: 40px 0;
                animation: text 0.6s 1.8s ease backwards;
    
                @keyframes text {
                    0% {
                        opacity: 0;
                        transform: translateY(40px);
                    }
                }
    
                @media screen and (max-width: 799px) {
                    min-height: 400px;
                    height: 80%;
                }
    
                article {
                    width: 600px;
                    position: absolute;
                    bottom: 0;
                    z-index: 4;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
    
    
                    @media screen and (max-width: 799px) {
                        width: 100%;
                    }
    
                    p {
                        color: white;
                        font-size: 18px;
                        letter-spacing: 0.6px;
                        margin-bottom: 40px;
                        text-shadow: 6px 6px 10px #32243e;
                    }
    
                    button {
                        height: 45px;
                        padding: 0 30px;
                        border-radius: 50px;
                        cursor: pointer;
                        box-shadow: 0px 15px 20px rgba(#36184f, 0.5);
                        z-index: 3;
                        color: #17375E;
                        background-color: white;
                        text-transform: Capitalize;
                        font-weight: 500;
                        font-size: 16px;
                        transition: all 0.3s ease;
    
    
                        &:hover {
                            box-shadow: 0px 10px 10px -10px rgba(#36184f, 0.5);
                            transform: translateY(5px);
                            background: #F65B56;
                            color: white;
                        }
                    }
                }
            }
    
            // The 404 Number
            .p404 {
                font-size: 200px;
                font-weight: 700;
                letter-spacing: 4px;
                color: white;
                display: flex !important;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 2;
                animation: anime404 0.6s cubic-bezier(0.3, 0.8, 1, 1.05) both;
                animation-delay: 1.2s;
    
                @media screen and (max-width: 799px) {
                    font-size: 100px;
                }
    
                @keyframes anime404 {
                    0% {
                        opacity: 0;
                        transform: scale(10) skew(20deg, 20deg);
                    }
                }
    
                li:nth-of-type(2) {
                    color: #36184f;
                    z-index: 1;
                    animation-delay: 1s;
                    filter: blur(10px);
                    opacity: 0.8;
                }
    
    
            }
    
            // Bigger Circle
            .circle {
                position: absolute;
    
                &:before {
                    content: '';
                    position: absolute;
                    width: 800px;
                    height: 800px;
                    background-color: rgba(#36184f, 0.2);
                    border-radius: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    box-shadow: $circleShadow;
                    animation: circle 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
    
    
                    @keyframes circle {
                        0% {
                            width: 0;
                            height: 0;
                        }
                    }
    
                    @media screen and (max-width: 799px) {
                        width: 400px;
                        height: 400px;
                    }
                }
            }
    
            // Container 1
            .one {
                .content {
    
                    // Smaller Circle
                    &:before {
                        content: '';
                        position: absolute;
                        width: 600px;
                        height: 600px;
                        background-color: rgba(#36184f, 0.3);
                        border-radius: 100%;
                        box-shadow: $circleShadow;
                        animation: circle 0.8s 0.4s cubic-bezier(1, 0.06, 0.25, 1) backwards;
    
                        @media screen and (max-width: 799px) {
                            width: 300px;
                            height: 300px;
                        }
                    }
    
                    .piece {
                        background: linear-gradient(90deg, #8077ea 13.7%, #eb73ff 94.65%);
    
                        &:nth-of-type(1) {
                            right: 15%;
                            top: 18%;
                            height: 30px;
                            width: 120px;
                            animation-delay: 0.5s;
                            animation-name: pieceRight;
                        }
    
                        &:nth-of-type(2) {
                            left: 15%;
                            top: 45%;
                            width: 150px;
                            height: 50px;
                            animation-delay: 1s;
                            animation-name: pieceLeft;
                        }
    
                        &:nth-of-type(3) {
                            left: 10%;
                            top: 75%;
                            height: 20px;
                            width: 70px;
                            animation-delay: 1.5s;
                            animation-name: pieceLeft;
                        }
                    }
                }
            }
    
            // Container 2
            .two {
                .content {
                    .piece {
                        background: linear-gradient(90deg, #ffedc0 0%, #ff9d87 100%);
    
                        &:nth-of-type(1) {
                            left: 0%;
                            top: 25%;
                            height: 40px;
                            width: 120px;
                            animation-delay: 2s;
                            animation-name: pieceLeft;
                        }
    
                        &:nth-of-type(2) {
                            right: 15%;
                            top: 35%;
                            width: 180px;
                            height: 50px;
                            animation-delay: 2.5s;
                            animation-name: pieceRight;
                        }
    
                        &:nth-of-type(3) {
                            right: 10%;
                            top: 80%;
                            height: 20px;
                            width: 160px;
                            animation-delay: 3s;
                            animation-name: pieceRight;
                        }
    
                    }
                }
            }
            // Container 3
            .three {
                .content {
                    .piece {
                        background: #fb8a8a;
    
                        &:nth-of-type(1) {
                            left: 25%;
                            top: 35%;
                            height: 20px;
                            width: 80px;
                            animation-name: pieceLeft;
                            animation-delay: 3.5s;
                        }
    
                        &:nth-of-type(2) {
                            right: 10%;
                            top: 55%;
                            width: 140px;
                            height: 40px;
                            animation-name: pieceRight;
                            animation-delay: 4s;
                        }
    
                        &:nth-of-type(3) {
                            left: 40%;
                            top: 68%;
                            height: 20px;
                            width: 80px;
                            animation-name: pieceLeft;
                            animation-delay: 4.5s;
                        }
                    }
                }
            }
        }
    } 
`;

const WrapperOrderDetail = styled.div`
    with: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 294px);
    padding: 0px 16px;

    #order-detail{
        .ant-drawer{
            z-index: 9999;
        }
        .drawer-style-instruction{
            display: flex;
            justify-content: center;
            align-items: center;

            .processing-general{
                background-color: #ffff;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 18px;
                
                .content-processing{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    h1{
                        font-size: 21px;
                        margin-bottom: 16px;
                        color: #17375E;
                    }
                    p{
                        font-size: 14px;
                        margin: 0px;
                        color: #2d2d2d;
                    }

                    /* HTML: <div class="loader"></div> */
                    .loader {
                        width: 100px;
                        aspect-ratio: 1;
                        display: grid;
                        border: 8px solid #0000;
                        border-radius: 50%;
                        border-color: #ccc #0000;
                        animation: l16 1s infinite linear;
                    }
                    .loader::before, .loader::after {    
                        content: "";
                        grid-area: 1/1;
                        margin: 2px;
                        border: inherit;
                        border-radius: 50%;
                    }
                    .loader::before {
                        border-color: #17375E #0000;
                        animation: inherit; 
                        animation-duration: 0.5s;
                        animation-direction: reverse;
                    }
                    .loader::after {
                        margin: 8px;
                    }
                    @keyframes l16 { 
                        100%{transform: rotate(1turn)}
                    }
                }
            }

            .ant-drawer-header-custom{
                background-color: #ffff;
                padding: 16px;
                position: sticky;
                top: 0;
                z-index: 999;
                h1{
                    font-size: 18px;
                    color: #17375E;
                    margin: 0px;
                }
                .default-button-close{
                    color: #1c1c1c;
                    position: absolute;
                    left: 16px;
                    top: 0px;
                }
                .close-button{
                    font-size: 18px;
                }
                .countdown-number{
                    font-size: 14px;
                    border-radius: 15px;
                    width: 24px;
                    height: 24px;
                    border: 1px solid #ffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .ant-drawer-body{
                padding: 0px;
                width: 100%;

                .segmented-style{
                    background-color: #ffff;

                    .ant-segmented-item{
                        border-radius: 5px;
                        color: #1c1c1c !important;
                        background-color: #ffff;
                    }
                    .ant-segmented-item:hover{
                        background-color: #ffff !important;
                    }
                    .ant-segmented-item-selected{
                        background-color: #9d9d9d !important;
                        color: #17375E;
                    }
                }
                .content-instruction{
                    padding: 16px;
                    .alert-message{
                        text-align: start;
                        border-radius: 5px;
                        padding: 8px;
                        .ant-alert-message{
                            font-size: 16px;
                        }
                    }
                    .qr-section{
                        text-align: start;
                        h1{
                            font-size: 18px;
                            color: #17375E;
                        }
                        p{
                            font-size: 16px;
                            color: #1c1c1c;
                        }
                    }
                    .manual-section{
                        text-align: start;
                        h1{
                            font-size: 18px;
                            color: #17375E;
                        }
                        p{
                            font-size: 16px;
                            color: #1c1c1c;
                        }
                        .box-manual{
                            background-color: #2d2d2d;
                            border-radius: 5px;
                            margin: 18px 0px 8px 0px;
                            p{
                                color: #eeee;
                                margin: 0px;
                                font-size: 16px;
                            }
                            .access-data-box{
                                border-bottom: 0.5px solid #eeee;
                                padding: 18px;
                                .custom{
                                    color: #ffff;
                                    font-size: 16px;
                                    font-weight: 600;
                                    margin: 8px 0px 0px 0px !important;
                                    width: 100%;
                                }
                            }
                        }
                        .note-info{
                            margin-bottom: 18px;
                            border-radius: 5px;
                            .ant-alert-message{
                                font-size: 16px;
                                color: #1c1c1c;
                                font-weight: 600;
                            }
                        }
                    }
                    .btn-close-intructions{
                        width: 300px;
                        height: 45px;
                        background: #17375E;
                        color: #ffff;
                        border-radius: 10px;
                        text-transform: capitalize;
                        margin-top: 16px;
                        border: unset !important;
                    }
                }
            }
        }

        @media screen and (max-width: 768px){
            .drawer-style-instruction{
                .processing-general{
                    
                    .content-processing{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        h1{
                            font-size: 21px;
                            margin-bottom: 16px;
                            color: #17375E;
                        }
                        p{
                            font-size: 14px;
                            margin: 0px;
                            color: #2d2d2d;
                        }

                        /* HTML: <div class="loader"></div> */
                        .loader {
                            width: 100px;
                            aspect-ratio: 1;
                            display: grid;
                            border: 8px solid #0000;
                            border-radius: 50%;
                            border-color: #ccc #0000;
                            animation: l16 1s infinite linear;
                        }
                        .loader::before, .loader::after {    
                            content: "";
                            grid-area: 1/1;
                            margin: 2px;
                            border: inherit;
                            border-radius: 50%;
                        }
                        .loader::before {
                            border-color: #17375E #0000;
                            animation: inherit; 
                            animation-duration: 0.5s;
                            animation-direction: reverse;
                        }
                        .loader::after {
                            margin: 8px;
                        }
                        @keyframes l16 { 
                            100%{transform: rotate(1turn)}
                        }
                    }
                }
                .ant-drawer-body{
                    padding: 0px;
                    width: 100%;
                    
                    .segmented-style{
                        background-color: #ffff;

                        .ant-segmented-item{
                            border-radius: 5px;
                            color: #1c1c1c !important;
                            background-color: #ffff;
                        }
                        .ant-segmented-item:hover{
                            background-color: #ffff !important;
                        }
                        .ant-segmented-item-selected{
                            background-color: #9d9d9d !important;
                            color: #17375E;
                        }
                    }
                    .content-instruction{
                        padding: 16px;
                        .alert-message{
                            .ant-alert-message{
                                font-size: 14px;
                            }
                        }
                        .qr-section{
                            h1{
                                font-size: 16px;
                            }
                            p{
                                font-size: 14px;
                            }
                        }
                        .manual-section{
                            text-align: start;
                            h1{
                                font-size: 16px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .box-manual{
                                background-color: #2d2d2d;
                                border-radius: 5px;
                                margin: 18px 0px 8px 0px;
                                p{
                                    color: #eeee;
                                    margin: 0px;
                                    font-size: 14px;
                                }
                                .access-data-box{
                                    border-bottom: 0.5px solid #eeee;
                                    padding: 18px;
                                    .custom{
                                        color: #ffff;
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin: 8px 0px 0px 0px !important;
                                        width: 100%;
                                    }
                                }
                            }
                            .note-info{
                                .ant-alert-message{
                                    font-size: 12px;
                                }
                            }
                        }
                        .btn-close-intructions{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .order-detail-container{
        max-width: 1400px;
        .cover-steps{
            height: fit-content;

            .line-step{
                background: #eeee;
                height: 6px;
                border-radius: 3px;
                width: 100%;
            }
            .active{
                background: #17375E;
                height: 6px;
            }
            .custom-steps{
                .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
                    background-color: #17375E;
                }
                .ant-steps-item-finish{
                    .ant-steps-icon{
                        color: #17375E;
                    }
                }
                .ant-steps-item-process{
                    .ant-steps-icon{
                        color: #17375E;
                    }
                }
                .ant-steps-item-wait{
                    .ant-steps-icon{
                        color: #9d9d9d;
                    }
                }
            }

            @media screen and (max-width: 768px){
                padding-bottom: 16px;
                background-color: #ffff;
                position: sticky;
                top: 76px;
                z-index: 2;
            }
        }

        .title{
            text-align: start;
            height: fit-content;

            h1{
                font-size: 22px;
                color: #17375E;
                font-weight: 700;
                margin: 0px 0px 16px 0px; 
                text-transform: capitalize;
            }
            p{
                margin: 0px;
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
            }
        }
        .order-detail-card{
            height: fit-content;

            .plan-card{
                border: 1px solid #eeee;
                border-radius: 15px;
                box-sixing: border-box;
                background-color: #ffff;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                h1{
                    text-transform: Capitalize;
                    font-weight: 600;
                    margin: 0px;

                }
                .plan-title{
                    padding: 16px;
                    text-align: start;
                    h1{
                        font-size: 18px;
                        color: #1c1c1c;
                    }
                }
                .plan-style{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #1c1c1c;
                    border-bottom: 1px solid #1c1c1c;
                    padding: 16px;

                    .icon-style{
                        margin-right: 5px;
                    }
                    h1{
                        font-size: 16px;
                        color: #1c1c1c;
                    }
                    p{
                        margin: 0px;
                        font-size: 16px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
                .plan-price{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #eeee;
                    padding: 16px;
                    border-radius: 0px 0px 15px 15px;
                    h1{
                        font-size: 18px;
                        color: #1c1c1c;
                    }
                    .price{
                        color: #ff2600;
                    }
                }
            }

            @media screen and (max-width: 768px){
                
            }
        }
        .content-step-info{
            background-color: #ffff;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            border-radius: 10px;
            text-align: start;
            padding: 16px;
            height: fit-content;

            label{
                font-size: 16px;
                text-transform: capitalize;
            }
            .required{
                color: #ff0000;
            }
            .box-required{
                border: 1px solid #ff0000;
            }
            .input-box{
                margin-top: 8px;
                border-radius: 6px;
            }
            .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
                background-color: #17375E;
                border-color: transparent;
            }
            .ant-checkbox-inner:after {
                transform: rotate(45deg) scale(1) translate(-30%, -50%);
            }
            .terms-condition{
                line-height: unset;
                padding-bottom: 16px;
                display: flex;
                justify-content: start;
                align-items: center;
                
                .ant-checkbox-inner{
                    width: 24px;
                    height: 24px;   
                    border: 1.5px solid #17375E; 
                }
            }
            .cover_method_payment{
                padding : 16px;
                border: 1px solid #eeee;
                border-radius: 6px;
                margin-bottom: 16px;
                background-color: #ffff;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                cursor: pointer;

                .check_box{
                    border-right: 2px solid #17375E;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 16px;
                    .check{
                        font-size: 18px;
                        color: #17375E;
                    }
                    .checked{
                        font-size: 18px;
                        color: #17375E;
                    }
                }
                .method_box{
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    padding-left: 16px;
                    img{
                        width: 40px;
                    }
                    h1{
                        font-size: 16px;
                        margin: 0px 0px 0px 8px;
                        font-weight: 500;
                    }
                }
            }
            .cover_method_payment_disabled{
                background-color: rgba(0, 0, 0, 0.04);
                border: 1px solid #9d9d9d;
                filter: unset;
                cursor: not-allowed;
                .check_box{
                    .check{
                        font-size: 18px;
                        color: #9d9d9d;
                    }
                }
                .method_box{
                    h1{
                        color: rgba(0, 0, 0, 0.45);
                    }
                } 
            }
            .review-plan-card{
                .icon-style{
                    margin-right: 5px;
                }
                .price-color{
                    color: #F65B56;
                    font-size: 18px;
                    font-weight: 700;
                }
                h1{
                    font-size: 16px;
                    color: #1c1c1c;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin: 0px;
                }
                p{
                    margin: 0px;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
                .box-style{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #1c1c1c;
                    border-bottom: 1px solid #1c1c1c;
                    padding: 16px 0px;
                }
            }
            .btn-back{
                width: 100%;
                height: 45px;
                background-color: #ffffff;
                border: 1px solid #17375E;
                border-radius: 6px;
                color: #17375E;
            }
            .btn-next{
                width: 100%;
                height: 45px;
                background-color: #17375E;
                color: #ffff;
                border-radius: 6px;
            }
            .button-confirm-continue{
                width: 100%;
                height: 45px;
                background-color: #17375E;
                border-radius: 6px;
                span{
                    color: #ffff !important;
                    margin: 0px !important;
                }
            }
            .ant-btn-default:disabled{
                color: rgba(0, 0, 0, 0.25);
                background: rgba(0, 0, 0, 0.04);
            }

            .review-esim-card-with-success{
                
                h1{
                    font-size: 18px;
                    margin: 0px 0px 16px 0px;
                }
                .ant-popconfirm-buttons{
                    .ant-btn{
                        height: 38px !important;
                        min-width: 120px !important;
                    }
                    .ant-btn-primary{
                        background-color: #17375E;
                        border: 1px solid #17375E;
                    }
                }
                .wrapper-button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 16px;
                    .button-save{
                        min-width: 100px;
                        margin-right: 8px;
                        background-color: #17375E;
                        color: #ffff;
                        font-weight: 500;
                        border: 1px solid #17375E;
                    }
                    .button-done{
                        min-width: 100px;
                        margin-left: 8px;
                        color: #17375E;
                        font-weight: 500;
                        border: 1px solid #17375E;
                    }
                }
                #myqrcode{
                    p{
                        font-size: 14px;
                        margin: 0px;
                        font-weight: 400;
                        text-transform: capitalize;
                    }
                    span{
                        color: #17375E;
                        font-weight: 600;
                        font-size: 16px;
                        margin-top: 8px;
                    }
                    .ant-qrcode-expired {
                        position: relative;
                        color: transparent;
                    }
                    .ant-qrcode-expired::after {
                        content: "Not Found!";
                        position: absolute;
                        top: 0;
                        left: 13px;
                        color: black;
                        font-size: 18px;
                        text-align: center;
                        font-weight: 500;
                    }
                }
                .ant-popover{
                    max-width: 100%;
                    .ant-popover-inner-content{
                        max-width: 100%;
                        .ant-btn{
                            min-width: 90px;
                            height: 30px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 768px){

        }
    }
    
`;

const WrapperTermsCondition = styled.div`
    with: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 294px);
    padding: 0px 16px;

    .terms-condition-container{
        max-width: 1400px;
        text-align: start;
    }
`;

const WrapperDeviceSupport = styled.div`
    with: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 294px);
    padding: 0px 16px;

    .device-support-container{
        max-width: 1400px;
        .device-item{
            text-align: start;
            h1{
                font-size: 18px;
            }
            .model-container{
                display: contents;
                .model{
                    width: fit-content;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    border-radius: 30px;
                    height: 46px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #e9ebee;
                    color: #2a2a2e;
                    margin: 0px 18px 18px 0px;

                }
            }
        }
    }
`;

export { DefaultContainer, HeaderWrapper, WrapperPlanCard, FooterWrapper, WrapPageNotFound, WrapperOrderDetail, WrapperTermsCondition, WrapperDeviceSupport };