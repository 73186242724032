import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef } from "react";

PaymentForm.propTypes = {
    propPaymentInfo: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
}

function PaymentForm({ propPaymentInfo, onClose }) { 

    const form = useRef();
    const isSubscribed = useRef(true);

    useEffect(() => {
        const interval = setInterval(() => {
            if (document.getElementById('aba-checkout')) {
                if (document.getElementById('aba-checkout').style.display === 'none') {
                    clearInterval(interval);
                    onClose()
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [onClose]);

    const checkOut = useCallback(() => {
        try {
            // eslint-disable-next-line no-undef
            AbaPayway.checkout();
        } catch (e) {
            // error, normally because ABA script haven't load
            setTimeout(function () {
                checkOut()
            }, 500);
        }
    }, [])

    useEffect(() => {
        if (isSubscribed.current) {
            checkOut()
        }
        return () => {
            isSubscribed.current = false;
        };
    }, [checkOut]);

    return (
        <div>
            <form method='POST' ref={form} id='aba_merchant_request' target="aba_webservice" action='https://checkout-sandbox.payway.com.kh/api/payment-gateway/v1/payments/purchase' >
                <input type='hidden' name='hash' value={ propPaymentInfo['hash'] } />
                <input type='hidden' name='amount' value={ propPaymentInfo['amount'] } />
                <input type='hidden' name='tran_id' value={ propPaymentInfo['tranId'] } />
                <input type='hidden' name='req_time' value={ propPaymentInfo['reqTime'] } />
                <input type='hidden' name='payment_option' value={ propPaymentInfo['paymentOption'] } />
                <input type='hidden' name='firstname' value={ propPaymentInfo['firstname'] } />
                <input type='hidden' name='lastname' value={ propPaymentInfo['lastname'] } />
                <input type='hidden' name='phone' value={ propPaymentInfo['phone'] } />
                <input type='hidden' name='email' value={ propPaymentInfo['email'] } />
                <input type='hidden' name='merchant_id' value={ propPaymentInfo['merchantId'] } />
                <input type='hidden' name='continue_success_url' value={ propPaymentInfo['successUrl'] } />
                <input type='hidden' name='return_url' value={ propPaymentInfo['returnURL'] } />
            </form>
        </div>
    );
}

export default PaymentForm;