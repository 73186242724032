import { React } from 'react';

import { Row, Col } from 'antd';
import { MailFilled, PhoneFilled } from '@ant-design/icons';

import { FooterWrapper } from '../ComponentStyle';

const Footer = () => {

    const contactInfo = [
        {
            icon: <PhoneFilled style={{color: '#F65B56', fontSize: '16px'}}/>,
            content: '+855 123 456 789'
        },{
            icon: <MailFilled style={{color: '#F65B56', fontSize: '16px'}}/>,
            content: 'eSIM.support@gmail.com'
        }
    ];

    return (
        <FooterWrapper>
            <Row className='footer-container'>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className='about-us-info'>
                    <h1>About Us</h1>
                    <p>
                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                    </p>
                    <Row className='contact-info'>
                        {contactInfo.map((item, index) => (
                            <Col span={24} key={index} className={ index + 1 !== contactInfo.length ? 'add-margin contact-item' : 'contact-item'} >
                                {item.icon} <p>{item.content}</p>
                            </Col>
                        ))}
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className='copyright'>
                    <img src="/images/esim-logo-rectangle-fit-tr.png" alt="logo-esim" />
                    <p>Copyright © 2024, SpaciaNet Japan Inc., Ltd. All rights reserved.</p>
                </Col>
            </Row>
        </FooterWrapper>
    );
}

export default Footer;
