import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { encode as base64Encode } from 'base-64';

import { Row, Col, Button, Steps, Input, QRCode, Popconfirm, notification, Drawer, Segmented, Alert, Checkbox, Spin} from 'antd';
import { CalendarOutlined, WifiOutlined, GlobalOutlined, UserOutlined, DollarOutlined, CheckCircleOutlined, FileSearchOutlined, CheckCircleFilled, CloseOutlined, AppleOutlined, AndroidOutlined } from '@ant-design/icons'

import { WrapperOrderDetail } from "../../components/ComponentStyle";
import PaymentForm from "../../components/PaymentForm";

import { encryptHMAC } from '../../utils/encryption';
import { createOrder, getOrderDetail, getEsimQr, encryptData } from '../../utils/api';

import moment from 'moment';

function doDownload(url, fileName) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const OrderDetail = (isRobotProp) => {

    const location = useLocation();

    const [selectedPlan, setSelectedPlan] = useState(location.state?.selectedPlan || {});
    const [currentStep, setCurrentStep] = useState(0);
    const [isMobileView, setIsMobileView] = useState(false);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("paylater");
    const [paymentDetails, setPaymentDetails] = useState({});
    const [esimDetails, setEsimDetails] = useState({});
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [userSegment, setUserSegment] = useState('ios');
    const [isDrawerSegmentOpen, setIsDrawerSegmentOpen] = useState(true);
    const [hasReadInstructions, setHasReadInstructions] = useState(false);

    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userEmail, setUserEmail] = useState(null);
    const [userPhone, setUserPhone] = useState(null);

    const [hasAgreedToTermsOne, setHasAgreedToTermsOne] = useState(false);
    const [hasAgreedToTermsTwo, setHasAgreedToTermsTwo] = useState(false);

    const [api, contextMessageHolder] = notification.useNotification();

    const isSubscribedRef = useRef(false);
    const isRobotRef = useRef(false);
    const userRequiredRef = useRef(false);
    const qrIsValidRef = useRef(false);

    const defaultSteps = [
        {
            title: 'User Info',
            icon: <UserOutlined />,
            span: 4,
        },{
            title: 'Agreements',
            icon: <FileSearchOutlined />,
            span: 5,
        },{
            title: 'Payment',
            icon: <DollarOutlined />,
            span: 5,
        },{
            title: 'Confirm Payment',
            icon: <DollarOutlined />,
            span: 5,
        },{
            title: 'Get Your eSIM',
            icon: <CheckCircleOutlined />,
            span: 5,
        }
    ];
    const paylaterSteps = [
        {
            title: 'User Info',
            icon: <UserOutlined />,
            span: 6,
        },{
            title: 'Agreements',
            icon: <FileSearchOutlined />,
            span: 6,
        },{
            title: 'Payment',
            icon: <DollarOutlined />,
            span: 6,
        },{
            title: 'Order Details',
            icon: <CheckCircleOutlined />,
            span: 6,
        }
    ]
    const userInfo = [
        {
            label: 'First Name',
            name: 'first_name',
            placeholder: 'Enter first name',
            type: 'input',
            span: 12,
            required: true,
        },{
            label: 'Last Name',
            name: 'last_name',
            placeholder: 'Enter last name',
            type: 'input',
            span: 12,
            required: true
        },{
            label: 'Email',
            name: 'email',
            placeholder: 'Enter email',
            type: 'email',
            span: 24,
            required: false
        },{
            label: 'Phone Number',
            name: 'phone',
            placeholder: 'Enter phone',
            type: 'tell',
            span: 24,
            required: false
        }
    ];
    const paymentMethods = [    
        {
            name: 'ABA PAY',
            description: '',
            icon: '/images/img_aba_pay.png',
            value: 'abapay',
            disabled: true
        },{
            name: 'Credit / Debit Card',
            description: '',
            icon: '/images/img_aba_credit_card.png',
            value: 'cards',
            disabled: true

        },{
            name: 'KHQR',
            description: '',
            icon: '/images/img_bakong.png',
            value: 'bakong',
            disabled: true
        },
        {
            name: 'Book Now Pay later',
            description: '',
            icon: '/images/img_aba_pay.png',
            value: 'paylater',
            disabled: false
        },
    ];

    const handleNextStep = () => {
        if (isRobotRef.current || userRequiredRef.current) return;
    
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
        }
    };
    const handlePreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    const handleInputChange = (field, value) => {
        const fieldSetters = {
            'first_name': setUserFirstName,
            'last_name': setUserLastName,
            'email': setUserEmail,
            'phone': setUserPhone,
        };
    
        if (fieldSetters[field]) {
            fieldSetters[field](value);
        }
    };
    const handlePaymentOptionChange = (method) => {
        if (!method?.disabled) {
            setSelectedPaymentMethod(method.value);
        }
    };
    const handleSaveQRCode = () => {
        const qrCodeElement = document.getElementById('myqrcode')?.querySelector('svg');
    
        if (!qrCodeElement) {
            api.error({
                message: 'QR Code not found.',
                placement: 'topRight',
            });
            return;
        }
    
        const svgData = new XMLSerializer().serializeToString(qrCodeElement);
        const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const downloadUrl = URL.createObjectURL(blob);
    
        doDownload(downloadUrl, 'QRCode.svg');
    
        api.success({
            message: 'QR Code downloaded successfully!',
            placement: 'topRight',
        });
    };
    const handleCompletion = () => {
        window.location.replace('/');
    };
    const handleSegmentChange = (segment) => {
        setUserSegment(segment);
    };
    const handleAgreementChange = (isChecked, agreementType) => {
        if (agreementType === 'one') {
            setHasAgreedToTermsOne(isChecked);
        } else if (agreementType === 'two') {
            setHasAgreedToTermsTwo(isChecked);
        }
    };
    const closePaymentDrawer = () => {
        setIsDrawerOpen(false);
    };
    const closeInstructions = () => {
        if (qrIsValidRef.value) {
            setIsDrawerSegmentOpen(false);
        } else {
            setHasReadInstructions(true);
            startCountdown();
        }
    };

    const handleSubmit = async() => {
        if (isRobotRef.value) return;

        if (currentStep === 0) {
            if (!userFirstName || !userLastName) {
                userRequiredRef.value = true;
                return;
            }

            selectedPlan['email'] = userEmail ? userEmail : process.env.REACT_APP_EMAIL_RECEIVER_ESIM_INFO;
            selectedPlan['phone'] = userPhone;
            selectedPlan['customerName'] = `${userFirstName.trim()} ${userLastName.trim()}`;

            userRequiredRef.value = false;
        }

        if (currentStep === 1) {
            if (!hasAgreedToTermsOne || !hasAgreedToTermsTwo) return;
        }

        if(currentStep == 2) {
            await createOrder(selectedPlan)
            .then(async(response) => {
                setIsLoadingData(true);
                const result = response.data.data;
                const idEncrypt = await handleEncrypt({ id: result?.id });

                if(response.status == 201){
                    if(selectedPaymentMethod !== 'paylater') {

                        let tranId = result?.id
                        let amount = result?.price
                        let firstName = "Bunchheang"
                        let lastname = "thorn"
                        let phone = result?.phone || ''
                        let email = result?.email ? result?.email : process.env.REACT_APP_EMAIL_RECEIVER_ESIM_INFO
                        let reqTime = moment().format('YYYYMMDDHHmmss');
                        let merchantId = process.env.REACT_APP_ABA_MERCHANT_ID
                        let paymentOption = selectedPaymentMethod
                        let successUrl = `/order-detail?query=${idEncrypt}`
                        let returnURL = `/payments/success`
                        let returnURLBase64 = base64Encode(returnURL)
                        
                        let hashValue = reqTime + merchantId + tranId + amount  + firstName + lastname + email + phone + paymentOption + returnURLBase64 + successUrl
                        let apiKey = process.env.REACT_APP_ABA_KEY

                        const hmac = await encryptHMAC(apiKey, hashValue);

                        setPaymentDetails(prev => ({
                            ...prev,
                            hash: hmac,
                            tranId,
                            amount,
                            paymentOption: selectedPaymentMethod,
                            userFirstName,
                            userLastName,
                            phone: userPhone,
                            email: userEmail,
                            reqTime,
                            merchantId,
                            successUrl,
                            returnURL: returnURLBase64,
                        }));

                        handlePayClick();
                    }else{
                        setPaymentDetails(prev => ({
                            ...prev,
                            tranId: result?.id,
                            amount: result?.price,
                            paymentOption: selectedPaymentMethod,
                            phone: result?.phone || '',
                            email: result?.email ? result?.email : process.env.REACT_APP_EMAIL_RECEIVER_ESIM_INFO,
                        }));

                        window.location.href = `/order-detail?query=${idEncrypt}`;
                        handleNextStep();
                    }
                }
            })
            .catch((error) => {
                console.log('createOrder catch', error);
            }).finally(() => {
                setIsLoadingData(false);
            })

            return ;
        }

        if (currentStep === 3) {
            if (selectedPaymentMethod === 'paylater') {
                handleCompletion();
                return;
            } else {
                setIsDrawerSegmentOpen(true);
            }
        }

        if (currentStep === 4) {
            return;
        }

        handleNextStep();
    };
    const handlePayClick = () => {
        if (isRobotRef.value) return;
        setIsDrawerOpen(true);
    };
    const fetchOrderDetails = async (orderId) => {
        setIsLoadingData(true);
        
        try {
            const response = await getOrderDetail(orderId);
            const orderData = response.data?.data;
    
            if (response.status === 200 && orderData) {
                setSelectedPlan(orderData);
    
                const esimInfo = {
                    'order_id': orderData?.id,
                    'unique_code': orderData?.uniqueCode
                };
    
                if (orderData.paymentStatus == 'pending') {
                    setSelectedPaymentMethod('paylater');
                } else {
                    await fetchEsimDetails(esimInfo);
                }
            }
        } catch (error) {
            console.error("Error fetching order details:", error);
        } finally {
            setIsLoadingData(false);
            setCurrentStep(3);
        }
    };
    const fetchEsimDetails = async (data) => {
        try {
            const response = await getEsimQr(data);
            const esimData = response.data?.data;
    
            if (response.status === 201 && esimData) {
                setEsimDetails(esimData);
    
                if (esimData?.required_payment) {
                    setSelectedPaymentMethod('paylater');
                }
    
                if (esimData?.qrCodeContent) {
                    qrIsValidRef.value = true;
                }
            }
        } catch (error) {
            if (error.response?.status === 404 && error.response?.data?.message === "Not Found!") {
                qrIsValidRef.value = false;
            } else {
                console.error('Error fetching eSIM details:', error);
            }
        }
    };
    const requestEsimDetails = async () => {
        if (!selectedPlan?.id || !selectedPlan?.uniqueCode) return;
    
        const data = {
            'order_id': selectedPlan.id,
            'unique_code': selectedPlan.uniqueCode
        };
    
        await fetchEsimDetails(data);
    };
    const startCountdown = async (repeatCount = 3) => {
        const secondsToGo = 15;
        
        const countdown = () => {
            let remainingSeconds = secondsToGo;
    
            const timer = setInterval(() => {
                remainingSeconds -= 1;
    
                if (remainingSeconds <= 0) {
                    clearInterval(timer);
    
                    if (repeatCount > 1) {
                        const data = {
                            'order_id': selectedPlan?.id,
                            'unique_code': selectedPlan?.uniqueCode
                        };
    
                        fetchEsimDetails(data);
    
                        if (!qrIsValidRef.value) {
                            startCountdown(repeatCount - 1);
                        } else {
                            setHasReadInstructions(false);
                            setIsDrawerSegmentOpen(false);
                        }
                    } else {
                        setHasReadInstructions(false);
                        setIsDrawerSegmentOpen(false);
                    }
                }
            }, 1000);
        };
    
        countdown();
    };
    const handleEncrypt = async (data) => {
       return await encryptData(data).then(response => {
            return response;
        }).catch(error => {
            console.error('Error fetching Get:', error);
        });
    };

    useEffect(() => {
        const screenSize = window.innerWidth;
        
        const loadABAPaymentJS = () => {
            const scriptUrl = "https://checkout.payway.com.kh/plugins/checkout2-0.js?hide-close=2";
            const existingScript = document.querySelector(`script[src="${scriptUrl}"]`);
            
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = scriptUrl;
                script.async = true;
                document.body.appendChild(script);
    
                return () => {
                    if (document.body.contains(script)) {
                        document.body.removeChild(script);
                    }
                };
            }
        };
    
        const loadOrderDetails = async() => {
            const orderId = new URLSearchParams(window.location.search).get('query');
            if (orderId) {
                await fetchOrderDetails(orderId);
            }
        };
    
        if (!isSubscribedRef.value) {
            if (isRobotProp) {
                isRobotRef.value = isRobotProp['isRobotProp'].currentStep;
            }
    
            loadABAPaymentJS();
            loadOrderDetails();
        }
    
        if (screenSize < 768) {
            setIsMobileView(true);
        }
    
        return () => {
            isSubscribedRef.value = true;
        };
    }, []);
    
    return (
        <WrapperOrderDetail>
            {contextMessageHolder}
            <Spin spinning={isLoadingData} fullscreen />
            <Row gutter={[0, 32]} className='order-detail-container' id='order-detail'>
                {
                    selectedPaymentMethod === 'paylater' ? (
                        <Col span={isMobileView ? 24 : 12} className='cover-steps' order={1}>
                            {isMobileView ? (
                                <Row gutter={[3, 0]}>
                                    {paylaterSteps.map((item, index) => (
                                        <Col span={item.span} key={index}>
                                            <div className={currentStep >= index ? 'line-step active' : 'line-step'} />
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Steps current={currentStep} labelPlacement="vertical" size="small" items={paylaterSteps} className='custom-steps'/>
                            )}
                        </Col>
                    ) : (
                        <Col span={isMobileView ? 24 : 12} className='cover-steps' order={1}>
                            {isMobileView ? (
                                <Row gutter={[3, 0]}>
                                    {defaultSteps.map((item, index) => (
                                        <Col span={item.span} key={index}>
                                            <div className={currentStep >= index ? 'line-step active' : 'line-step'} />
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Steps current={currentStep} labelPlacement="vertical" size="small" items={defaultSteps} className='custom-steps'/>
                            )}
                        </Col>
                    )
                }
                <Col span={isMobileView ? 24 : 10} offset={isMobileView ? 0 : 2} className="title" order={2}>
                    <h1>{currentStep !== 3 ? 'Great Choice!' : "Great, you're all set!"}</h1>
                    <p>your plan detail:</p>
                </Col>
                <Col span={isMobileView ? 24 : 10} offset={isMobileView ? 0 : 2} className='order-detail-card' order={isMobileView ? 3 : 4}>
                    <Row className='plan-card'>
                        <Col span={24} className="plan-title">
                            <h1>Plan Details</h1>
                        </Col>
                        <Col span={24} className='plan-style'>
                            <p><GlobalOutlined className='icon-style' /> coverage:</p>
                            <h1>{selectedPlan?.wrGroup}</h1>
                        </Col>
                        <Col span={24} className='plan-style'>
                            <p><WifiOutlined className='icon-style' /> data:</p>
                            <h1>{selectedPlan?.data}</h1>
                        </Col>
                        <Col span={24} className='plan-style' style={currentStep === 3 ? { borderBottom: '0px' } : {}}>
                            <p><CalendarOutlined className='icon-style' /> validity:</p>
                            <h1>{selectedPlan?.days} Days</h1>
                        </Col>
                        {currentStep !== 3 && (
                            <Col span={24} className="plan-price">
                                <h1>Total Price:</h1>
                                <h1 className='price'>USD {selectedPlan?.price}</h1>
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col span={isMobileView ? 24 : 12} className="content-step-info" order={isMobileView ? 4 : 3}>
                    <Row gutter={[16, 16]}>
                        {currentStep === 0 &&
                            userInfo.map((field, index) => {
                                const defaultValue = {
                                    'first_name': userFirstName,
                                    'last_name': userLastName,
                                    'email': userEmail,
                                    'phone': userPhone,
                                }[field.name] || "";
                                return (
                                    <Col span={field.span} key={index}>
                                        <label>
                                            {field.label} {field.required && <span className="required">*</span>}
                                        </label>
                                        <Input
                                            type={field.type}
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            onChange={(e) => handleInputChange(field.name, e.target.value)}
                                            size="large"
                                            className="input-box"
                                            defaultValue={defaultValue}
                                        />
                                    </Col>
                                );
                            }
                        )}
                        {currentStep === 1 && selectedPlan && (
                            <Col span={24} style={{marginTop: '20px'}}>
                                <Checkbox 
                                    checked={hasAgreedToTermsOne} 
                                    onChange={(e) => handleAgreementChange(e.target.checked, 'one')}
                                    className='terms-condition'
                                >
                                    My device supports eSIM. Check 
                                    <a 
                                        href={`${process.env.REACT_APP_BASE_URL}/device-support`} 
                                        target="_blank" 
                                        rel="noreferrer" 
                                        style={{ color: '#17375E', fontWeight: 'bold' }}
                                    >
                                        your device
                                    </a>
                                </Checkbox>
                                <Checkbox
                                    checked={hasAgreedToTermsTwo}
                                    onChange={(e) => handleAgreementChange(e.target.checked, 'two')}
                                    className="terms-condition"
                                >
                                    Agree to eSIM 
                                    <a 
                                        href={`${process.env.REACT_APP_BASE_URL}/terms-and-conditions`} 
                                        target="_blank" 
                                        rel="noreferrer" 
                                        style={{ color: '#17375E', fontWeight: 'bold' }}
                                    >
                                        Terms and Conditions
                                    </a>
                                </Checkbox>
                            </Col>
                        )}
                        {currentStep === 2 && (
                            <Col span={24}>
                                {paymentMethods.map((method, index) => (
                                    <Row
                                        key={index}
                                        className={`cover_method_payment ${method.disabled ? 'cover_method_payment_disabled' : ''}`}
                                        onClick={() => handlePaymentOptionChange(method)}
                                    >
                                        <Col className="check_box">
                                            {selectedPaymentMethod === method.value ? (
                                                <CheckCircleFilled className="checked" />
                                            ) : (
                                                <CheckCircleOutlined className="check" />
                                            )}
                                        </Col>
                                        <Col className="method_box">
                                            <img src={method.icon} alt={method.name} />
                                            <h1>{method.name}</h1>
                                        </Col>
                                    </Row>
                                ))}

                                {isDrawerOpen && (
                                    <PaymentForm 
                                        propPaymentInfo={paymentDetails} 
                                        onClose={closePaymentDrawer} 
                                        id="aba-form-payment" 
                                    />
                                )}
                            </Col>
                        )}
                        {currentStep === 3 && (
                            <Col span={24} className="review-esim-card-with-success" id="review-esim">
                                <Row gutter={[0, 16]} style={{ width: '100%' }} id="myqrcode">
                                    <Col
                                        span={24}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                        }}
                                        id="myqrcode"
                                    >
                                        <h1 style={{ fontWeight: selectedPaymentMethod === 'paylater' ? '400' : '500' }}>
                                            {selectedPaymentMethod === 'paylater' ? (
                                                <>
                                                    Your order has been created. Please{' '}
                                                    <span style={{ fontWeight: '700', color: '#17375E' }}>COPY</span> the{' '}
                                                    <span style={{ fontWeight: '700', color: '#17375E' }}>PAYMENT ID</span>{' '}
                                                    and <span style={{ fontWeight: '700', color: '#17375E' }}>TRANSACTION ID</span>{' '}
                                                    to a secure place. These will be used to make payment via ABA and retrieve your eSIM.
                                                </>
                                            ) : (
                                                'Your payment has been successfully processed. Please keep your Transaction ID and Payment ID as a backup for your eSIM QR code in case of any issues.'
                                            )}
                                        </h1>
                                    </Col>

                                    {[
                                        { label: 'Transaction ID', value: selectedPlan.id },
                                        { label: 'Payment ID', value: selectedPlan.uniqueCode },
                                    ].map((item, idx) => (
                                        <Col
                                            span={24}
                                            key={idx}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'start',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <p>{item.label}:</p>
                                            <span>{item.value}</span>
                                        </Col>
                                    ))}

                                    <Popconfirm
                                        placement="top"
                                        title="Are you sure?"
                                        description={
                                            selectedPaymentMethod === 'paylater' ? (
                                                <div>
                                                    <p>
                                                        <strong>Important:</strong> Please make sure to save the{' '}
                                                        <strong>PAYMENT ID ({selectedPlan.uniqueCode})</strong> and{' '}
                                                        <strong>TRANSACTION ID ({selectedPlan.id})</strong> in a secure place.
                                                    </p>
                                                    <p>
                                                        These IDs are essential for completing your payment on ABA and retrieving your eSIM QR
                                                        code. Without them, you will not be able to proceed with the payment or access your
                                                        eSIM.
                                                    </p>
                                                </div>
                                            ) : (
                                                "Make sure you have kept your Transaction ID and PAYMENT ID as a backup before confirming."
                                            )
                                        }
                                        okText="Confirm"
                                        cancelText="Cancel"
                                        getPopupContainer={() => document.getElementById('review-esim')}
                                        onConfirm={handleSubmit}
                                    >
                                        <Button className="button-confirm-continue">Done</Button>
                                    </Popconfirm>
                                </Row>
                            </Col>
                        )}
                        {currentStep === 4 && (
                            <Col span={24}>
                                {esimDetails ? (
                                    <Row className="review-esim-card-with-success" id="review-esim">
                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} id="myqrcode">
                                            {
                                                qrIsValidRef.value ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <h1> Congratulations! Your plan is now available</h1>
                                                        <h1>Scan the QR below ( make the QR presented on your device )</h1>
                                                    </div>
                                                ) : (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <h1>Your order has been successfully created. We are awaiting the processing of your eSIM.</h1>
                                                    </div>
                                                )
                                            }
                                            {
                                                !isLoadingData && (
                                                    <Row gutter={[0, 16]} style={{ marginBottom: '16px', marginTop: '16px', width: '100%' }}>
                                                        {[
                                                            { label: 'Transaction ID', value: selectedPlan.id },
                                                            { label: 'Confirm Code', value: selectedPlan.uniqueCode },
                                                        ].map((item, idx) => (
                                                            <Col span={24} key={idx} style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column' }}>
                                                                <p>{item.label} :</p>
                                                                <span>{item.value}</span>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                )
                                            }
                                            <QRCode
                                                type="svg"
                                                errorLevel="H"
                                                value={qrIsValidRef.value ? esimDetails.qrCodeContent : process.env.REACT_APP_BASE_URL}
                                                size={250}
                                                status={qrIsValidRef.value ? 'active' : 'loading'}
                                                icon="/favicon.ico"
                                            />
                                        </Col>
                                        <Col span={24} className="wrapper-button" id="wrapper-button-style">
                                            <Button className="button-save" onClick={qrIsValidRef.value ? handleSaveQRCode : requestEsimDetails}>
                                                {qrIsValidRef.value ? 'Save' : 'Request Again'}
                                            </Button>
                                            <Popconfirm
                                                placement="top"
                                                title="Are you sure?"
                                                description="Make sure you have saved the image before you confirm 'CONFIRM'!"
                                                okText="Confirm"
                                                cancelText="Cancel"
                                                getPopupContainer={() => document.getElementById('review-esim')}
                                                onConfirm={handleCompletion}
                                            >
                                                <Button className="button-done">Finish</Button>
                                            </Popconfirm>
                                        </Col>
                                        <Drawer
                                            className='drawer-style-instruction'
                                            title={null}
                                            placement="bottom"
                                            closable={false}
                                            open={isDrawerSegmentOpen}
                                            height={'100%'}
                                            onClose={() => closeInstructions()}
                                            getContainer={() => document.getElementById('order-detail')}
                                        >   
                                            {
                                                hasReadInstructions ? (
                                                    <Row className='processing-general'>
                                                        <Col span={24} className='content-processing' >
                                                            <div className="loader"></div>
                                                            <h1>Proccessing QR Code</h1>
                                                            <p>Processing your QR code. This may take a moment—please stay connected to Wi-Fi and keep this screen open. Thank you for your patience!</p>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Row>
                                                        <Col span={24} className='ant-drawer-header-custom'>
                                                            <Row>
                                                                <Col span={24} style={{marginBottom: '16px'}}>
                                                                    <h1>View Instructions</h1>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Segmented
                                                                        options={[
                                                                            { label: 'IOS', value: 'ios', icon: <AppleOutlined /> },
                                                                            { label: 'Android', value: 'android', icon: <AndroidOutlined /> },
                                                                        ]}
                                                                        block
                                                                        className='segmented-style'
                                                                        defaultValue={userSegment}
                                                                        onChange={(value) => handleSegmentChange(value)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={24} className='content-instruction'>
                                                            <Row className='qr-code'>
                                                                <Col span={24}>
                                                                    <Alert
                                                                        message="Warning"
                                                                        description="WARNING! Most eSIMs can only be installed once. If you remove the eSIM from your device, you cannot install it again."
                                                                        type="warning"
                                                                        showIcon
                                                                        className='alert-message'
                                                                        banner={true}
                                                                    />
                                                                    {
                                                                        userSegment === 'ios' ? (
                                                                            <Row gutter={[0, 16]}>
                                                                                <Col span={24} className='qr-section'>
                                                                                    <h1># Install eSIM With QR Code</h1>
                                                                                    {
                                                                                        [
                                                                                            {key: '1', value: 'Go to Settings > Cellular/Mobile Data > Add eSIM or Set up Cellular/Mobile Service > Use QR Code on your device.'},
                                                                                            {key: '2', value: 'Scan the QR code or take a screenshot (available up to iOS 17.3), tap "Open Photos" , select it from your camera roll, tap "Next", then tap "Continue" twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap "Done".'},
                                                                                            {key: '3', value: 'Choose a label for your new eSIM plan.'},
                                                                                            {key: '4', value: 'Choose "Primary" for your default line, then tap "Continue".'},
                                                                                            {key: '5', value: 'Choose the "Primary" you want to use with iMessage and FaceTime for your Apple ID, then tap "Continue".'},
                                                                                            {key: '6', value: 'Choose your new eSIM plan for cellular/mobile data, then tap "Continue".'},

                                                                                        ].map((item, idx) => (
                                                                                            <p key={idx} style={ idx === 0 ? {marginTop: '0px'} : {}}>{item.key}. {item.value}</p>
                                                                                        ))
                                                                                    }
                                                                                </Col>
                                                                                <Col span={24} className='manual-section'>
                                                                                    <h1># Install eSIM By Manual</h1>
                                                                                    <Row className='box-manual'>
                                                                                        {
                                                                                            [
                                                                                                {label: 'SM-DP+ ADDRESS', content: 'NA'},
                                                                                                {label: 'ACTIVATION CODE', content: 'NA'},
                                                                                            ].map((item, idx) => (
                                                                                                <Col span={24} className='access-data-box' key={idx} style={ idx === 1 ? {borderBottom: '0px'} : {}} >
                                                                                                    <p>{item.label}</p>
                                                                                                    <p className='custom'>{item.content}</p>
                                                                                                </Col>
                                                                                            ))
                                                                                        }
                                                                                    </Row>
                                                                                    <Alert message="Note : You will get the SM-DP+ Address and Activation Code at the next step." type="info" className='note-info'/>
                                                                                    {
                                                                                        [
                                                                                            {key: '1', value: 'Go to Settings > Cellular/Mobile Data > Add eSIM or Set up Cellular/Mobile Service > Use QR Code on your device.'},
                                                                                            {key: '2', value: 'Tap "Enter Details Manually" and enter the SM-DP+ Address and Activation Code by copying them, tap "Next", then tap "Continue" twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap "Done".'},
                                                                                            {key: '3', value: 'Choose a label for your new eSIM plan.'},
                                                                                            {key: '4', value: 'Choose "Primary" for your default line, then tap "Continue".'},
                                                                                            {key: '5', value: 'Choose the "Primary" you want to use with iMessage and FaceTime for your Apple ID, then tap "Continue".'},
                                                                                            {key: '6', value: 'Choose your new eSIM plan for cellular/mobile data, then tap "Continue".'},
                                                                                        ].map((item, idx) => (
                                                                                            <p key={idx} style={ idx === 0 ? {marginTop: '0px'} : {}}>{item.key}. {item.value}</p>
                                                                                        ))
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        ) : (
                                                                            <Row gutter={[0, 16]}>
                                                                                <Col span={24} className='qr-section'>
                                                                                    <h1># Install eSIM With QR Code</h1>
                                                                                    {
                                                                                        [
                                                                                            {key: '1', value: 'Go to Settings > Cellular/Mobile Data > Add eSIM or Set up Cellular/Mobile Service > Use QR Code on your device.'},
                                                                                            {key: '2', value: 'Scan the QR code or take a screenshot (available up to iOS 17.3), tap "Open Photos" , select it from your camera roll, tap "Next", then tap "Continue" twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap "Done".'},
                                                                                            {key: '3', value: 'Choose a label for your new eSIM plan.'},
                                                                                            {key: '4', value: 'Choose "Primary" for your default line, then tap "Continue".'},
                                                                                            {key: '5', value: 'Choose the "Primary" you want to use with iMessage and FaceTime for your Apple ID, then tap "Continue".'},
                                                                                            {key: '6', value: 'Choose your new eSIM plan for cellular/mobile data, then tap "Continue".'},

                                                                                        ].map((item, idx) => (
                                                                                            <p key={idx} style={ idx === 0 ? {marginTop: '0px'} : {}}>{item.key}. {item.value}</p>
                                                                                        ))
                                                                                    }
                                                                                </Col>
                                                                                <Col span={24} className='manual-section'>
                                                                                    <h1># Install eSIM By Manual</h1>
                                                                                    <Row className='box-manual'>
                                                                                        {
                                                                                            [
                                                                                                {label: 'SM-DP+ ADDRESS', content: 'NA'},
                                                                                                {label: 'ACTIVATION CODE', content: 'NA'},
                                                                                            ].map((item, idx) => (
                                                                                                <Col span={24} className='access-data-box' key={idx} style={ idx === 1 ? {borderBottom: '0px'} : {}} >
                                                                                                    <p>{item.label}</p>
                                                                                                    <p className='custom'>{item.content}</p>
                                                                                                </Col>
                                                                                            ))
                                                                                        }
                                                                                    </Row>
                                                                                    <Alert message="Note : You will get the SM-DP+ Address and Activation Code at the next step." type="info" className='note-info'/>
                                                                                    {
                                                                                        [
                                                                                            {key: '1', value: 'Go to Settings > Cellular/Mobile Data > Add eSIM or Set up Cellular/Mobile Service > Use QR Code on your device.'},
                                                                                            {key: '2', value: 'Tap "Enter Details Manually" and enter the SM-DP+ Address and Activation Code by copying them, tap "Next", then tap "Continue" twice and wait for a while. Your eSIM will connect to the network, this may take a few minutes, then tap "Done".'},
                                                                                            {key: '3', value: 'Choose a label for your new eSIM plan.'},
                                                                                            {key: '4', value: 'Choose "Primary" for your default line, then tap "Continue".'},
                                                                                            {key: '5', value: 'Choose the "Primary" you want to use with iMessage and FaceTime for your Apple ID, then tap "Continue".'},
                                                                                            {key: '6', value: 'Choose your new eSIM plan for cellular/mobile data, then tap "Continue".'},
                                                                                        ].map((item, idx) => (
                                                                                            <p key={idx} style={ idx === 0 ? {marginTop: '0px'} : {}}>{item.key}. {item.value}</p>
                                                                                        ))
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                    <Button className='btn-close-intructions' onClick={() => closeInstructions()}>
                                                                        Continue
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Drawer>
                                    </Row>
                                ) : (
                                    <Row className="review-esim-card-with-failed">
                                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <h1>Processing Failed</h1>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        )}

                        {/* Footer */}
                        {currentStep > 0 && currentStep < 3 && (
                            <Col span={12}>
                                <Button className="btn-back" onClick={handlePreviousStep}>
                                    Back
                                </Button>
                            </Col>
                        )}

                        {currentStep < 3 && (
                            <Col span={currentStep > 0 && currentStep < 3 ? 12 : 24}>
                                <Button 
                                    className="btn-next" 
                                    disabled={
                                        (currentStep === 1 && (!hasAgreedToTermsOne || !hasAgreedToTermsTwo)) ||
                                        isRobotRef.value ||
                                        (currentStep === 0 && (userFirstName === '' || userLastName === ''))
                                    } 
                                    onClick={handleSubmit}
                                >
                                    {currentStep === 2 ? 'Pay Now' : 'Continue'}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </WrapperOrderDetail>
    );
}

export default OrderDetail;
