import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import en from './en.json';
import kh from './kh.json';

const resources ={
	kh: {
		translation:kh
	},
	en: {
		translation:en
	},
};

i18n
	.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        debug: false,
        saveMissing: true,
        fallbackLng: ['kh', 'en'],
        supportedLngs: ['kh', 'en'],
        // lng: 'kh',
        interpolation: {
            escapeValue: false, 
        },
        react: {
            bindI18n: 'languageChanged editorSaved',
        },
        detection: {
            // order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            cookieOptions: { path: '/', sameSite: 'strict' }
        },
    })


export default i18n;