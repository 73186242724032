// src/App.js
import { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { reCaptchaRespone } from './utils/api';

import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './page/home';
import OrderDetail from './page/home/OrderDetail';
import TermsAndConditions from './page/home/TermsAndConditions';
import DeviceSupport from './page/home/DeviceSupport';
import NotFound from './page/NotFound';

import './App.css';

function App() {

    const [isFound, setIsFound] = useState(false);

    const isSubscribed = useRef(false);
    const isRobot = useRef(false);

    const checkPageFound = () => {
        const path = window.location.pathname;
        if(path == '/' || path == '/order-detail' || path == '/terms-and-conditions' || path == '/device-support') {
            setIsFound(true);
        }else{
            setIsFound(false);
        }
    }

    const onSubmitReCaptcha = () => {

        if (!window.grecaptcha) {
            return;
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute().then((token) => {
                    if (token) {
                        const data = { token };
    
                        reCaptchaRespone(data)
                            .then((response) => {
                                const result = response.data.data;
    
                                if (result.success) {
                                    isRobot.value = result.score > 0.8 ? false : true;
                                    if (result.score < 0.9) {
                                        window.location.href = "/";
                                    }
                                } else {
                                    console.error("Error verifying reCAPTCHA:", result);
                                }
                            })
                            .catch((error) => {
                                console.error("Backend error verifying reCAPTCHA:", error);
                            });
                    } else {
                        console.error("reCAPTCHA token not found");
                    }
                })
                .catch((error) => {
                    console.error("reCAPTCHA execution error:", error);
                });
        });
    };

    useEffect(() => {
        if(!isSubscribed.value) {
            const scriptRecaptcha = document.createElement("script")
            scriptRecaptcha.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}`
            scriptRecaptcha.addEventListener("load", onSubmitReCaptcha)
            document.body.appendChild(scriptRecaptcha)
            checkPageFound();
        }

        return () => {
            isSubscribed.value = true;
        }
    }, []);


    return (
        <Router>
            <div className="App">
                <main>
                    {
                        isFound && <Header className="App-header"/>
                    }
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/order-detail" element={<OrderDetail isRobotProp={isRobot} />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="/device-support" element={<DeviceSupport />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    {
                        isFound && <Footer className="App-footer"/>
                    }
                    <div
                        className="g-recaptcha"
                        data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        data-size="invisible"
                    ></div>
                </main>
            </div>
        </Router>
    );
}

export default App;