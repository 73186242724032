import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';

import { WrapperTermsCondition } from '../../components/ComponentStyle';

const { Title, Paragraph } = Typography;


const TermsAndConditions = () => {
    return (
        <WrapperTermsCondition>
            <Row className='terms-condition-container'>
                <Col span={24}>
                    <Typography style={{ textAlign: 'left' }}>
                        <Title level={2}>Terms and Conditions</Title>
                        <Divider />
                        <Paragraph>
                            Welcome to our eSIM platform. By using our services, you agree to abide by the following terms and conditions. Please read them carefully.
                        </Paragraph>
                        <Title level={3}>1. General</Title>
                        <Paragraph>
                            These terms and conditions govern your use of our eSIM platform. By accessing or using our services, you agree to these terms in full.
                        </Paragraph>
                        <Title level={3}>2. User Responsibilities</Title>
                        <Paragraph>
                            You must ensure that your account details are kept secure and confidential. Any activity conducted under your account is your responsibility.
                        </Paragraph>
                        <Title level={3}>3. Privacy Policy</Title>
                        <Paragraph>
                            We are committed to protecting your privacy. Please review our Privacy Policy for information on how we collect, use, and disclose your personal data.
                        </Paragraph>
                        <Title level={3}>4. Service Availability</Title>
                        <Paragraph>
                            While we strive to ensure uninterrupted service, there may be occasional downtime due to maintenance or unforeseen issues.
                        </Paragraph>
                        <Title level={3}>5. Amendments</Title>
                        <Paragraph>
                            We reserve the right to amend these terms at any time. Changes will be effective immediately upon posting on our website.
                        </Paragraph>
                        <Title level={3}>6. Contact Us</Title>
                        <Paragraph>
                            If you have any questions or concerns, please contact our support team at support@example.com.
                        </Paragraph>
                        <Divider />
                        <Paragraph>
                            By using our services, you acknowledge that you have read, understood, and agree to these terms and conditions.
                        </Paragraph>
                    </Typography>
                </Col>
            </Row>
        </WrapperTermsCondition>
    );
}

export default TermsAndConditions;
